import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss';
import App from './app';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
ReactGA.send("pageview");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);